import React from "react";
import {Setting} from "./pages/setting";
import {Client} from "./components/client/client";
import './styles/App.css'
import {Header} from "./components/header";
import {Main} from "./pages/main";
import {useDataContext} from "./DataContext";
import {ErrorPage} from "./pages/errorPage";
import {useParams} from "react-router-dom";


export const AppRoot = () => {

    const { clientData, setting,userData, client, authStatus } = useDataContext();
    const { pageName } = useParams();

    return (
        <div id="app" className="app">
            <Header/>
            {setting && userData && authStatus && !pageName && <Setting />}
            {client && clientData  && <Client />}
            {!client && pageName && <ErrorPage/>}
            {!authStatus && !pageName && <Main/>}
        </div>
    );
};




