import lang from "../../utils/lang.json";
import React, {ChangeEvent, useEffect} from "react";
import {useDataContext} from "../../DataContext";
import {socketSend} from "../../socket/socket";
import {useDebounce} from "../../utils/utils";

export const InputsNameDonate = () => {
    const {userData, setUserData, checkName} = useDataContext()
    const debouncedName = useDebounce(userData.name, 500);

    const nameChange = (field: 'name') => (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const regex = /^[a-zA-Z0-9.,]*$/;
        if (regex.test(inputValue)) {
            setUserData({...userData, [field]: inputValue})
        }
    };

    const handleChange = (field: 'minDonate') => (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const normalizedValue = inputValue.replace(',', '.');
        const parsedValue = normalizedValue === '' ? '' : parseFloat(normalizedValue);
        setUserData({...userData, [field]: parsedValue})
    };

    useEffect(() => {
        if (userData.name !== 'Name' && debouncedName !== 'Name')
        socketSend('name', { changeName: debouncedName, wallet: userData.wallet })
            .then(() => {})
            .catch(() => {
            });

    }, [debouncedName, userData.wallet]);

    return (
        <div className={'inputs'}>
            <div className={'nickname'}>
                <div className={'case-input'}>
                    <label
                        id={'label-name'}
                        className="label"
                        htmlFor="name"
                        style={{
                            color: checkName === 'invalid' ? '#ff0000' : (checkName ? '#D2F019' : '#ff0000')
                        }}>
                        {checkName === 'invalid' ? lang.en.setting.advanced.label1["invalid-name"] : (checkName ? lang.en.setting.advanced.label1["your-nickname"] : lang.en.setting.advanced.label1["nickname-taken"])}
                    </label>
                    <input
                        id="name"
                        className="text-input text-input-s"
                        type="text"
                        autoComplete={'off'}
                        value={userData.name}
                        onChange={nameChange("name")}
                        maxLength={35}
                    />
                </div>
            </div>
            <div className={'min-donate'}>
                <div className={'case-input'}>
                    <label className="label" htmlFor="minDonate">
                        {lang.en.setting.advanced.label2}
                    </label>
                    <input
                        id="minDonate"
                        className="text-input text-input-s"
                        pattern="[0-9]*"
                        inputMode="decimal"
                        autoComplete={'off'}
                        type="number"
                        value={userData.minDonate === '' ? '' : userData.minDonate}
                        onChange={handleChange("minDonate")}
                        placeholder="0.00"
                    />
                </div>
            </div>
        </div>
    )
}
