import minus from "../../img/ic-minus.svg";
import plus from "../../img/ic-plus.svg";
import React, {useEffect, useRef, useState} from "react";
import {useDataContext} from "../../DataContext";
import {getTonPrice, useDebounce} from "../../utils/utils";

export const InputDonate = () => {
    const {messageData, setMessageData, clientData} = useDataContext()
    const inputDonateRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [currentPrice, setCurrentPrice] = useState<number>(1)
    const debouncedDonate = useDebounce(messageData.amount, 1000)

    useEffect(() => {
        if (debouncedDonate < clientData.minDonate) {
            setMessageData({...messageData, amount: clientData.minDonate});
        }
    }, [debouncedDonate])

    const step: number = 0.01

    const minusValue = () => {
        const newValue= (messageData.amount - step).toFixed(3)
        const value = parseFloat(newValue) < 0 ? 0 : parseFloat(newValue);
        setMessageData({...messageData, amount: value})
    };

    const plusValue = () => {
        const value = (messageData.amount + step).toFixed(3)
        setMessageData({...messageData, amount: parseFloat(value)})
    };

    const focusInput = () => {
        const inputElement = document.getElementById('minDonate') as HTMLInputElement;
        if (inputElement){
            inputElement.focus();
        }
    }

    const changeValue = (value: number) => {
        if (!isNaN(value)) {
            setMessageData({...messageData, amount: value});
        }
    }
    const change = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;

        if (inputValue.length > 5) {
            inputValue = inputValue.slice(0, 5);
        }

        const newValue = parseFloat(inputValue).toFixed(3);

        if (!isNaN(parseFloat(newValue))) {
            changeValue(parseFloat(newValue));
        }
    };

    useEffect(() => {
        setMessageData({...messageData, amount: clientData.minDonate})
    }, [clientData.minDonate]);

    useEffect(() => {
        const fetchPrice = async () => {
            const tonPrice = await getTonPrice();
            if (tonPrice !== null) {
                setCurrentPrice(tonPrice);
            }
        };
        fetchPrice()
            .then(() => { })
            .catch(() => { });

        const interval = setInterval(fetchPrice, 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="amount-input" onClick={() => focusInput()}>
            <div
                id={'minus'}
                className={'icon-input minus'}
                onClick={() => minusValue()}>
                <img
                    className={'icon'}
                    src={minus}
                    alt="minus"
                />
            </div>

            <div className="amount">
                <div id={'text-donate'} className={'text-donate'}>
                    <input id="minDonate"
                           className="value-donate"
                           ref={inputDonateRef}
                           contentEditable={'true'}
                           type="number"
                           value={messageData.amount}
                           maxLength={5}
                           pattern="[0-9]*"
                           inputMode="decimal"
                           step={0.01}
                           onChange={change}
                    />
                    <h5 id={'h5'}>ton</h5>
                </div>
                <span
                    ref={spanRef}
                    style={{
                        position: 'absolute',
                        visibility: 'hidden',
                        whiteSpace: 'pre',
                        fontFamily: "Inter, sans-serif",
                        fontSize: "24px",
                        fontWeight: "800",
                        lineHeight: "32.4px",
                        letterSpacing: "0.02em"
                    }}
                >{messageData.amount} </span>
                <label className="amount-label" htmlFor="minDonate">~{(currentPrice * messageData.amount).toFixed(3)}$</label>
            </div>
            <div
                id={'plus'}
                className={'icon-input plus'}
                onClick={() => plusValue()}>
                <img
                    className={'icon'}
                    src={plus}
                    alt="plus"
                />
            </div>
        </div>
    )
}
