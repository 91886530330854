import '../styles/main.css'
import React from "react";
import {useTonConnectModal} from "@tonconnect/ui-react";
export const Main = () => {
    const { open } = useTonConnectModal();
    return(
        <div className={'main'}>
            <div className={'img-wrapper'}>
                <img className={'img-main'} src={'/assets/mainImg.png'} alt={'img-main'}/>
                <div className={'text-main'}>
                    <h1>Cryptocurrency donations for streamers</h1>
                    <p>Receive donations from your subscribers, they will be voiced live on the most popular streaming platforms</p>
                    <button onClick={() => open()} className={'start-for-free'}>start for free</button>
                </div>
            </div>
        </div>
    )
}
