import React from 'react';
import {socketSend} from "../socket/socket";

export const useDebounce = <S,>(value: S, delay: number = 300) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler: NodeJS.Timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return debouncedValue;
}


interface ForState {
    start: () => void;
    stop: () => void;
}

export const forState: ForState = (() => {
    let intervalId: number | undefined;

    const start = () => {
        if (intervalId === undefined) {
            intervalId = window.setInterval(() => socketSend('compound', true), 2000);
        }
    };
    const stop = () => {
        if (intervalId !== undefined) {
            clearInterval(intervalId);
            intervalId = undefined;
            // console.log('Interval stopped');
        }
    };

    return {
        start,
        stop
    };
})();


export const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
        console.log('Текст скопирован в буфер обмена');
    }).catch((e) => {
        console.error('Не удалось скопировать текст: ', e);
    });
};

export const getTonPrice = async (): Promise<number | null> => {
    const url = 'https://tonapi.io/v2/rates?tokens=ton&currencies=usd';
    try {
        const response = await fetch(url);

        const data = await response.json();
        return data.rates.TON.prices.USD;
    } catch (e) {
        return null;
    }
};

export const dateC = (date: Date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};






