import React, {useEffect} from "react";
import '../../styles/client.css';
import '../../styles/index.css'
import {useDataContext} from "../../DataContext";
import {InputDonate} from "./inputDonate";
import {InputName} from "./inputName";
import {InputMessage} from "./inputMessage";
import {ButtonPay} from "./button";
import {getWalletsJetton} from "./walletsJetton";
import {Info} from "./info";

export const Client = () => {

    const {clientData, userData, messageData,setMessageData} = useDataContext();

    const test = () => {
        getWalletsJetton(userData.wallet)
            .then(data => console.log("Полученные данные:", data))
            .catch(error => console.error("Ошибка:", error));
    }

    useEffect(() => {
        setMessageData({...messageData,
            uid: clientData._id,
            clientId: userData._id,
            clientWallet: userData.wallet,
            amount: clientData.minDonate,
            name: clientData.name,
            wallet: clientData.wallet,
        })
    }, [clientData, userData]);



    return (
        <div className={'client'}>
            <div id="clientMemo" className="client-memo">
                <Info />
                <div className={'inputs-client'}>
                    <InputDonate />
                    <InputName />
                    <InputMessage />
                    <div className={'ic-voice'}>
                        <div className={'icons-voice'}>
                            <div className={'icon-voice1'}/>
                            <div className={'icon-voice2'}/>
                            <div className={'icon-voice3'}/>
                        </div>
                        Your message will be voiced on the stream
                    </div>
                </div>
                <ButtonPay />
            </div>
        </div>
    );
};
