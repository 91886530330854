import send from "../../img/ic-send.svg";
import React, {useCallback} from "react";
import {useDataContext} from "../../DataContext";
import {Address, toNano} from "@ton/core";
import {bodyTx} from "./bodyTx";
import {socketSend} from "../../socket/socket";
import {useTonAddress, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";

export const ButtonPay = () => {

    const {messageData, clientData, userData,setMessageData} = useDataContext()

    const [tonConnectUI] = useTonConnectUI();
    const tonAddress = useTonAddress(true);
    const { open } = useTonConnectModal();

    tonConnectUI.uiOptions = {
        actionsConfiguration: {
            returnStrategy: 'back'
        }
    };

    const pay = useCallback(async () => {
        if (!!tonAddress && messageData.amount >= clientData.minDonate) {
            try {
                const res = await tonConnectUI.sendTransaction({
                    validUntil: Math.floor(Date.now() / 1000) + 360,
                    messages: [
                        {
                            address: Address.parse(`${messageData.wallet}`).toString({bounceable: false}),
                            amount: toNano(messageData.amount * 0.95).toString(),
                            // payload: bodyTx(clientData.wallet, userData.wallet, `${messageData.amount * 0.95}`)
                        },
                        {
                            address: 'UQBJ612eiQD58Jz4MPJ7oaVryO61swz_2NzDHLtqDBYtYaon',
                            amount: toNano(messageData.amount * 0.05).toString(),
                        }
                    ]
                });
                // setMessageData({...messageData, boc: res.boc })


                socketSend('comment', {...messageData, boc: res.boc })
                    .then(() => { })
                    .catch(() => { });
            } catch (e) {
                console.log('cancel', e);
            }
        } else {
            open();
        }

    }, [tonAddress, tonConnectUI, clientData.wallet, messageData.amount, open]);

    return (
        <div className="pay">
            <button className="button-pay"  onClick={pay}>
                <img className={'icon-send'} src={send} alt="Copy"/>
                send {messageData.amount} ton
            </button>
        </div>
    )
}



