import './styles/App.css';
import './styles/resetCss.css';
import './styles/client.css';
import './styles/inputs.css'
import './styles/fonts.css'
import './fonts.css';
import React, {FC} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {TonConnectUIProvider} from "@tonconnect/ui-react";
import { AppRoot } from "./appRoot";
import { LinkObs } from "./pages/linkObs";
import {DonationsObs} from "./components/donations/obs/donationsObs";
import { DataProvider } from './DataContext';

const App: FC = () => {
    return (
        <TonConnectUIProvider
            manifestUrl="https://donaton.tech/tonconnect-manifest.json">
            <Router>
                <Routes>
                    <Route path="/:pageName"
                           element={
                            <DataProvider>
                               <AppRoot />
                            </DataProvider>}
                    />
                    <Route path="/"
                           element={
                            <DataProvider>
                                <AppRoot />
                            </DataProvider>
                        }
                    />
                    <Route path="/obs/:_id" element={<LinkObs />} />
                    <Route path="/commnets/:token" element={<DonationsObs />} />
                </Routes>
            </Router>
        </TonConnectUIProvider>
    );
};

export default App;


