import React, { useState, useEffect, useRef } from "react";
import { socketSend } from "../socket/socket";
import "../styles/setting.css";
import '../styles/design.css';
import {UserUpdate} from "../types/types";
import {SwitchSettings} from "../components/switchSettings";
import {Design} from "../components/design/design";
import {StreamSetup} from "../components/streamSetup/streamSetup";
import {Advanced} from "../components/advanced/advanced";
import {Donations} from "../components/donations/donations";
import lang from '../utils/lang.json'
import {useDataContext} from "../DataContext";




export const Setting = () => {
    const { userData } = useDataContext();

    const token = localStorage.getItem('userToken');
    const [streamSetup, setStreamSetup] = useState<boolean>(false)
    const [design, setDesign] = useState<boolean>(false)
    const [advanced, setAdvanced] = useState<boolean>(false)
    const [formData, setFormData] = useState<UserUpdate>({
        userData: userData,
        token: ''
    });
    const isInitialMount = useRef(true);
    const buttonDoneRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (userData && !!token) {
            setFormData({
                userData: userData,
                token: token
            });
            isInitialMount.current = true;
        }
    }, [userData, token]);
    useEffect(() => {
        if (userData && token) {
            setFormData(prev => ({
                ...prev,
                userData: {
                    ...userData
                }
            }));
        }
    }, [userData, token]);


    const [activeTab, setActiveTab] = useState('advanced');
    const updateTabStates = (tab: string) => {
        setStreamSetup(tab === 'streamSetup');
        setDesign(tab === 'design');
        setAdvanced(tab === 'advanced');
    };

    useEffect(() => {
        updateTabStates(activeTab);
    }, [activeTab]);

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    }

    const done = () => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            socketSend('settings', formData)
                .then(() => {})
                .catch((error) => {});
            return;
        }
    };


    return (
        <div id={'donateAppBody'} className="donate-app-body">
            <div className={'settings'}>
                <div className={'switch-set'}>
                    <h1>Donation settings</h1>
                    <SwitchSettings onTabChange={handleTabChange} />
                    {streamSetup && <StreamSetup />}
                    {design && <Design />}
                    {advanced && <Advanced />}
                    <button id={'buttonDone'} className="save-settings" onClick={done} ref={buttonDoneRef}>
                        <h3>{lang.en["button-save"]}</h3>
                    </button>
                </div>
            </div>
            <Donations />
        </div>
    );
};
