import React, {useEffect, useState} from "react";
import '../../../styles/donations.css';
import {socket, socketSend} from "../../../socket/socket";
import {CommentType, ForUserMessage} from "../../../types/types";
import fire from '../../../img/ic-fire.svg'
import {dateC, forState} from "../../../utils/utils";
import {useParams} from "react-router-dom";

export const DonationsObs = () => {
    const [viewComments, setViewComments] = useState<CommentType[]>([]);
    const { token } = useParams<{ token: string }>();

    useEffect(() => {
        if (token) {
            socketSend('commentsObs', token)
                .then(() => {})
                .catch(() => {});
        }
    }, [token]);

    forState.start()

    useEffect(() => {
        socket.onmessage = (event: MessageEvent) => {
            const receivedMessage = JSON.parse(event.data);
            const { commentsObs }: any = receivedMessage;

            if (commentsObs) {
                const  {meComments, newComment}: ForUserMessage = commentsObs
                if (meComments) {
                    console.log(meComments)
                    setViewComments(prevCommentsData => {
                        const existingIds = new Set(prevCommentsData.map(comment => comment._id));
                        const newComments = meComments.filter((comment: CommentType) => !existingIds.has(comment._id));
                        return [...prevCommentsData, ...newComments];
                    });
                }
                if (newComment){
                    setViewComments(prevMeCommentsData => {
                        const commentExists = prevMeCommentsData.some(comment => comment._id === newComment._id);
                        if (!commentExists) {
                            return [newComment, ...prevMeCommentsData];
                        }
                        return prevMeCommentsData;
                    });
                }

            }

        };
    }, [viewComments]);

    return (
        <div className='donations '>
            <div className='donations-comments'
                 style={{
                     display: viewComments.length > 0 ? 'block' : 'none'}}>
                <div className='comments' style={{position: "relative", zIndex: 1}}>
                    {viewComments.length > 0 ? viewComments.map((comment) => (
                        <div key={comment._id} className='comment fade-in-element'>
                            <div className='user'>
                                <div className='image-user'>
                                    <img
                                        src={`https://donaton.tech/upload/${viewComments.length > 0 ? comment.clientId : comment.uid}.png`}
                                        alt='img'
                                        className='image-uid'
                                        onError={(e) => {
                                            e.currentTarget.style.display = 'none';
                                            const emojiElement = e.currentTarget.nextElementSibling as HTMLElement;
                                            if (emojiElement) {
                                                emojiElement.style.display = 'inline';
                                            }
                                        }}
                                    />
                                    <img src={fire} alt={'🔥'} className='emoji' style={{display: 'none'}}>
                                    </img>

                                </div>

                                <div className='name-message'>
                                    <div className='name-comment'>
                                        <h2>{comment.nameClient}</h2>
                                    </div>
                                    <div className='text-comment'>
                                        <p style={{color: 'white'}}>{comment.text}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='date-amount'>
                                <h2>{comment.amount} ton</h2>
                                <p style={{color: 'white'}}>{dateC(new Date(comment.createdAt))}</p>
                            </div>
                        </div>
                    )) : (
                        <div/>
                    )}
                </div>
            </div>
        </div>
    );
};
