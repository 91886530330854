import lang from "../../../utils/lang.json";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useDataContext} from "../../../DataContext";

export const InputsFonts = () => {
    const {userData, setUserData} = useDataContext();
    const [isOpenTitle, setIsOpenTitle] = useState<boolean>(false);
    const [isOpenMessage, setIsOpenMessage] = useState<boolean>(false);
    const titleRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef<HTMLDivElement>(null);

    const colorChange = (field: 'colorLeft' | 'colorRight' | 'colorFontDonate' | 'colorFontMessage') => (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setUserData({...userData, [field]: inputValue})
    };

    const handleFontChange = (field: 'fontDonate' | 'fontMessage', font: string) => {
        setUserData({...userData, [field]: font});
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (titleRef.current && !titleRef.current.contains(event.target as Node)) {
                setIsOpenTitle(false);
            }
            if (messageRef.current && !messageRef.current.contains(event.target as Node)) {
                setIsOpenMessage(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={'font-inputs'}>
            <div className="title-select-fonts">
                <div className={'case-input'} onClick={() => setIsOpenTitle(!isOpenTitle)}
                     ref={titleRef}>
                    <label className="label" htmlFor="temp2">{lang.en.setting.design.label1}</label>
                    <div className="select-font">
                        <div
                            className={`custom-select ${isOpenTitle ? 'open' : ''}`}
                        >
                            <div className="custom-select-trigger">{userData.fontDonate}</div>
                            {isOpenTitle && (
                                <div className="custom-options">
                                    {['Arial', 'Gotham Pro', 'Roboto', 'Poppins', 'Ruslan Display'].map((font) => (
                                        <span
                                            key={font}
                                            style={{fontFamily: `${font}, sans-serif`}}
                                            className={`custom-option ${userData.fontDonate === font ? 'selected' : ''}`}
                                            onClick={() => handleFontChange('fontDonate', font)}>
                                                {font}
                                            </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <input
                    id="colorFontDonate"
                    className="input-color"
                    type="color"
                    value={userData.colorFontDonate}
                    onChange={colorChange("colorFontDonate")}
                />
            </div>
            <div className="message-select-fonts">
                <div className={'case-input'}
                     onClick={() => setIsOpenMessage(!isOpenMessage)}
                     ref={messageRef}>
                    <label className="label" htmlFor="temp2">{lang.en.setting.design.label2}</label>
                    <div className="select-font">
                        <div
                            className={`custom-select ${isOpenMessage ? 'open' : ''}`}
                        >
                            <div className="custom-select-trigger">{userData.fontMessage}</div>
                            {isOpenMessage && (
                                <div className="custom-options">
                                    {['Arial', 'Gotham Pro', 'Roboto', 'Poppins', 'Ruslan Display'].map((font) => (
                                        <span
                                            key={font}
                                            style={{fontFamily: `${font}, sans-serif`}}
                                            className={`custom-option ${userData.fontMessage === font ? 'selected' : ''}`}
                                            onClick={() => handleFontChange('fontMessage', font)}>
                                                {font}
                                            </span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <input
                    id="colorFontMessage"
                    className="input-color"
                    type="color"
                    value={userData.colorFontMessage}
                    onChange={colorChange("colorFontMessage")}
                />
            </div>
        </div>
    )
}
