import React, {useState, useEffect, useRef} from "react";
import "../styles/switchSettings.css";
import lang from '../utils/lang.json'

interface SwitchSettingsProps {
    onTabChange: (tab: string) => void;
}

export const SwitchSettings: React.FC<SwitchSettingsProps> = ({ onTabChange }) => {
    const [activeTab, setActiveTab] = useState('advanced');
    const [borderStyle, setBorderStyle] = useState({ width: '104px', left: '0' });
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const select = document.getElementById(`${activeTab}`)
        if (divRef.current){
            const spanWidth = divRef.current.offsetWidth;

            const wight = select?.offsetWidth

            switch (activeTab) {
                case 'streamSetup':
                    setBorderStyle({ width: `${wight}px`, left: '0' });
                    break;
                case 'design':
                    setBorderStyle({ width: `${wight}px`, left: `${spanWidth + 24}px` });
                    break;
                case 'advanced':
                    setBorderStyle({ width: `${wight}px`, left: `${spanWidth + 98}px` });
                    break;
                default:
                    break;
            }
            onTabChange(activeTab);


        }

    }, [activeTab, onTabChange]);

    const selectTab = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <div className="tabs">
            <div
                id="streamSetup"
                ref={divRef}
                className={`tab ${activeTab === 'streamSetup' ? 'active' : ''}`}
                onClick={() => selectTab('streamSetup')}
            >
                <h3>{lang.en.setting.streamSetup.title}</h3>
            </div>
            <div
                id="design"
                className={`tab ${activeTab === 'design' ? 'active' : ''}`}
                onClick={() => selectTab('design')}
            >
                <h3>{lang.en.setting.design.title}</h3>
            </div>
            <div
                id="advanced"
                className={`tab ${activeTab === 'advanced' ? 'active' : ''}`}
                onClick={() => selectTab('advanced')}
            >
                <h3>{lang.en.setting.advanced.title}</h3>
            </div>
            <div
                id="borderTaps"
                className="border-taps"
                style={borderStyle}
            ></div>
        </div>
    );
};
