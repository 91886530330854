import React from "react";
import '../../styles/advanced.css';
import {useDataContext} from "../../DataContext";
import {ImgUpload} from "./imgUpload";
import {InputsNameDonate} from "./inputs";

export const Advanced = () => {
    const {userData, setUserData} = useDataContext()

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.checked;
        setUserData({...userData, verified: inputValue})
    };

    return (
        <div className={'advanced fade-in-element'}>
            <ImgUpload />
            <InputsNameDonate />
            <div className={'live-setting'}>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={userData.verified}
                        onChange={handleSwitchChange}
                    />
                    <span className="slider"></span>
                </label>
                <h3 style={{color: userData.verified? '#D2F019' : '#848484', fontWeight: '800'}}>
                    {userData.verified ? 'ONLINE' : 'OFFLINE'}
                </h3>
            </div>
        </div>
    );
};
