import logo from '../img/logoHeader.svg';
import '../styles/header.css';
import {useTonConnectModal, useTonAddress, useTonConnectUI, useIsConnectionRestored} from "@tonconnect/ui-react";
import React, {FC, useEffect} from "react";
import icon from '../img/ic-arrow.svg';
import tg from '../img/ic-tg.svg'
import load from '../img/ic-loader.svg'
import dis from '../img/ic-disconnect.svg'
import {useDataContext} from "../DataContext";
import {socketSend} from "../socket/socket";

export const Header: FC = () => {
    const { open } = useTonConnectModal();
    const [tonConnectUI] = useTonConnectUI();
    const [viewConnection, setViewConnection] = React.useState<boolean>(false);
    const { setAuthStatus, setClient, } = useDataContext()

    const address = useTonAddress();
    const rawAddress = useTonAddress(false);
    const connectionRestored = useIsConnectionRestored();

    const disconnect = async () => {
        if (address) {
            await tonConnectUI.disconnect();
            // localStorage.clear();
            [setViewConnection, setAuthStatus, setClient].forEach(fn => fn(false));
        }
    }

    const handleWalletClick = async () => {
        address ? setViewConnection(!viewConnection) : open();
    };


    useEffect(() => {

        !connectionRestored
            ? tonConnectUI.setConnectRequestParameters({ state: 'loading' })
            : !rawAddress && socketSend('connect', false).catch(() => {});

        const updateWalletText = () => {
            const element = document.getElementById('textConnect');
            if (!element) return;

            const sizeText = window.innerWidth < 430 ? 2 : 3;
            const originalText = address || (window.innerWidth < 430 ? 'connect' : 'connect wallet');
            element.innerText = originalText;

            while (element.scrollWidth > element.offsetWidth && originalText.length > sizeText * 2) {
                const startText = originalText.slice(0, sizeText);
                const endText = originalText.slice(-sizeText);
                element.innerText = `${startText}...${endText}`;
            }
        };

        updateWalletText();
        window.addEventListener('resize', updateWalletText);

        return () => {
            window.removeEventListener('resize', updateWalletText);
        };
    }, [address, connectionRestored]);

    return (
        <div className="donate-app-header">
            <div className="logo" onClick={() => window.location.href = '/'}>
                <img src={logo} alt="logo" />
            </div>
            <div className="buttons">
                <div className="tg-button"
                     onClick={() => window.open('https://t.me/donatonEN', '_blank', 'noopener,noreferrer')}>
                    <img src={tg}
                    alt={'tg'}/>
                </div>
                <div className={'ton-connect'}>
                    <div className="wallet" >
                        <div style={{width: address ? '100%' : 'auto'}}
                             onClick={handleWalletClick}
                             className="open-connect">
                            {!connectionRestored && (<img src={load} alt={'.'} className={'loader-icon'}/>)}


                            <div className="text-connect"
                                 id="textConnect"
                                 style={{display: !connectionRestored ? 'none': 'inline'}}></div>
                            <img className="img-connect" src={icon} alt="" style={{display: address ? 'inline' : 'none'}}/>
                        </div>
                    </div>
                    {(viewConnection &&
                        <span className={'select-wallet'}
                              onClick={() => disconnect()}>Disconnect
                              <img src={dis} alt={'disconnect'}/>
                         </span>
                    )}
                </div>
            </div>

        </div>
    );
};

