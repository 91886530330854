import {url} from "../../const/const";
import React, {useEffect, useState} from "react";
import {useDataContext} from "../../DataContext";


export const Info = () => {
    const {clientData} = useDataContext()
    const [imgExists, setImgExists] = useState(false);

    useEffect(() => {

        if (clientData.image) {
            const img = new Image();
            img.src = `https://donaton.tech/${clientData.image}`;
            img.onload = () => setImgExists(true);
            img.onerror = () => setImgExists(false);
        } else {
            setImgExists(false);
        }
    }, [clientData.image]);

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center"
        }}>
            <div className={'photo-user'}>
                {(imgExists &&
                    <img
                        src={`${url}/${clientData.image}?${new Date().getTime()}`}
                        onError={(e) => {
                            e.currentTarget.src = '';
                        }}
                        className={'img-st'}
                        alt={''}
                    />
                )}

            </div>
            <div className="name-st">
                {clientData.name}
            </div>
            <div className={'live'}
                 style={{background: clientData.verified ? 'rgba(219, 254, 1, 0.08)' : "#323232"}}>
                <div className={'live-ellipse'}
                     style={{background: clientData.verified ? 'rgba(210, 240, 25, 1)' : "rgb(132,132,132)"}}></div>
                <div className={'live-text'}
                     style={{color: clientData.verified ? 'rgba(210, 240, 25, 1)' : "rgb(132,132,132)"}}>
                    {clientData.verified ? "online" : "offline"}</div>
            </div>
        </div>
    )
}
