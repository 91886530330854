import React from "react";
import {useDataContext} from "../../DataContext";

export const InputMessage = () => {
    const { messageData, setMessageData} = useDataContext()

    const change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let inputValue = event.target.value;
        setMessageData({...messageData, text: inputValue})
    }

    return (
        <div className="message-input">
             <textarea id="messageSetting"
                       className="text-area"
                       autoComplete="off"
                       placeholder="Your message..."
                       maxLength={200}
                       onChange={change}>
             </textarea>
        </div>
    )
}
