import '../../styles/App.css'
import '../../styles/streamSetup.css'
import {copyToClipboard} from "../../utils/utils";
import lang from '../../utils/lang.json'
import {useDataContext} from "../../DataContext";

export const StreamSetup = () => {
    const { userData} = useDataContext()
    const token = localStorage.getItem('userToken');
    return(
        <div className={'stream-setup fade-in-element'}>
            <div className={'text-about'}>
                <div className={'number'}>1</div>
                <h4>{lang.en.setting.streamSetup.h1}</h4>
            </div>
            <div className={'link-input'}>
                <div className={'text'}>
                    <label className={'label'}>{lang.en.setting.streamSetup.label1}</label>
                    <h5>https://donaton.tech/{userData.name}</h5>
                </div>
                <button onClick={() => copyToClipboard(`https://donaton.tech/${userData.name}`)}
                        className={'button-copy'}>copy
                </button>
            </div>
            <div className={'text-about'}>
                <div className={'number'}>2</div>
                <h4>{lang.en.setting.streamSetup.h2}</h4>
            </div>
            <div className={'link-input'}>
                <div className={'text'}>
                    <label className={'label'}>{lang.en.setting.streamSetup.label2}</label>
                    <input
                        className={'obs-input'}
                        type={'password'}
                        value={`https://donaton.tech/obs/${userData._id}`}
                        readOnly={true}/>
                </div>
                <button onClick={() => copyToClipboard(`https://donaton.tech/obs/${token}`)}
                        className={'button-copy'}>copy
                </button>
            </div>
            <div className={'text-about'}>
                <div className={'number'}>3</div>
                <h4>{lang.en.setting.streamSetup.h3}</h4>
            </div>
            <div className={'link-input'}>
                <div className={'text'}>
                    <label className={'label'}>{lang.en.setting.streamSetup.label3}</label>
                    <input
                        className={'obs-input'}
                        type={'password'}
                        value={`https://donaton.tech/obs/${userData._id}`}
                        readOnly={true}/>
                </div>
                <button onClick={() => copyToClipboard(`https://donaton.tech/commnets/${token}`)}
                        className={'button-copy'}>copy
                </button>
            </div>

        </div>
    )
}
