import React from "react";
import "../../styles/design.css";
import {InputGif} from "./inputs/inputGif";
import {InputsFonts} from "./inputs/inputsFonts";

export const Design = () => {
    return (
        <div className={'view-message fade-in-element'}>
            <InputGif />
            <InputsFonts />
        </div>
    );
};
